import React from 'react';
import Grid from '@material-ui/core/Grid';

import { FacebookLogin } from './FacebookLogin';
import { DiscordLogin } from './DiscordLogin';
import { Logout } from './Logout';
import { FacebookPhoto } from '../Shared/FacebookPhoto';
import { UserAvatar } from '../Shared/UserAvatar';

export const UserStatus = ({ user }) => {
  if (user) {
    return (
      <React.Fragment>
        <Grid item>
          <UserAvatar user={user} />
        </Grid>
        <Grid item>
          <Logout />
          {!user.facebook && <FacebookLogin />}
          {!user.discord && <DiscordLogin />}
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FacebookLogin />
      </Grid>
      <Grid item xs={12}>
        <DiscordLogin />
      </Grid>
    </React.Fragment>
  );
};
