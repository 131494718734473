import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export const Header = () => (
  <AppBar position="static">
    <Toolbar>
      <Link to="/">
        <img src="/img/vortex-logo.svg" width="36" height="50" alt="Vortex logo" />
      </Link>
      <Link to="/" className="application-title">
        <h1>
          Vortex
        </h1>
      </Link>
      <Link to="/my-profile/">
        <Button label="My profile" color="inherit">
          My profile
        </Button>
      </Link>
      <Link to="/about/">
        <Button label="About" color="inherit">
          About
        </Button>
      </Link>
    </Toolbar>
  </AppBar>
);
