import { connect } from 'react-redux';

import { AddUnreal as AddUnrealComponent } from './AddUnreal';
import { commitUnreal, deleteUnreal } from '../../actions/unreal';
import { getCurrentUser, getUserUnreals } from '../../selectors/user';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUserId: state.currentUser.id,
    userUnreals: getUserUnreals(currentUser),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    commitUnreal: (unrealValue) => {
      dispatch(commitUnreal(stateProps.currentUserId, unrealValue));
    },
    deleteUnreal: (unrealValue) => {
      dispatch(deleteUnreal(stateProps.currentUserId, unrealValue));
    },
  };
};

export const AddUnreal = connect(mapStateToProps, null, mergeProps)(AddUnrealComponent);
