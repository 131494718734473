import { reference } from '../database';

// UNREAL - COMMIT

export const SEND_UNREAL = 'SEND_UNREAL';
const sendUnreal = (userId, unreal) => ({
  type: SEND_UNREAL,
  userId,
  unreal,
});

export const DELIVER_UNREAL = 'DELIVER_UNREAL';
const deliverUnreal = (userId, unreal) => ({
  type: DELIVER_UNREAL,
  userId,
  unreal,
});

export const commitUnreal = (userId, unreal) => (dispatch) => {
  dispatch(sendUnreal(userId, unreal));

  return reference(`users/${userId}/unreals/${unreal}`).set(true).then(() => {
    dispatch(deliverUnreal(userId, unreal));
  });
};

// UNREAL - DELETE

export const START_UNREAL_DELETE = 'START_UNREAL_DELETE';
const startDeletingUnreal = (userId, unreal) => ({
  type: START_UNREAL_DELETE,
  userId,
  unreal,
});

export const FINISH_UNREAL_DELETE = 'FINISH_UNREAL_DELETE';
const finishDeletingUnreal = (userId, unreal) => ({
  type: FINISH_UNREAL_DELETE,
  userId,
  unreal,
});

export const deleteUnreal = (userId, unreal) => (dispatch) => {
  dispatch(startDeletingUnreal(userId, unreal));

  return reference(`users/${userId}/unreals/${unreal}`).remove().then(() => {
    dispatch(finishDeletingUnreal(userId, unreal));
  });
};
