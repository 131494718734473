import { connect } from 'react-redux';

import { LinkTeam as LinkTeamComponent } from './LinkTeam';
import { fetchTeams, linkOrCreateTeam } from '../../actions/team';
import { deleteMembershipAlongTeam } from '../../actions/membership';
import { getUserTeams, getCurrentUser } from '../../selectors/user';
import { addKeysToObjects, contains } from '../../util';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const teamArray = addKeysToObjects(state.teams.items);
  const teamNames = teamArray.map(team => team.name);
  return {
    currentUserId: state.currentUser.id,
    currentUser,
    teams: teamArray,
    teamNames,
    userTeams: getUserTeams(state, currentUser),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const {
    userTeams,
    currentUserId,
  } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchTeams: () => dispatch(fetchTeams()),
    linkUserWithTeam: (teamName, teamTag) => {
      if (!contains(userTeams.map(team => team.name), teamName)) {
        dispatch(linkOrCreateTeam(currentUserId, teamName, teamTag));
      }
    },
    unlinkUserFromTeam: (teamName) => {
      const team = userTeams.find(team => team.name === teamName);
      if (team) {
        dispatch(deleteMembershipAlongTeam(currentUserId, team.id));
      }
    }
  };
};

export const LinkTeam = connect(mapStateToProps, null, mergeProps)(LinkTeamComponent);
