import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';

import { contains, addKeysToObjects } from '../../util';
import { SearchableTable, SearchableHeaderColumn } from '../Shared/SearchableTable';
import { LinkedUserAvatar } from '../Shared/UserAvatar';

const User = ({ user }) => (
  <TableRow>
    <TableCell>
      <LinkedUserAvatar user={user} />
    </TableCell>
    <TableCell>
      {user.nicknames.join(', ')}
    </TableCell>
  </TableRow>
);

export class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userNameFilterText: '',
      nickNameFilterText: '',
    };
  }

  userNameFilter = (user) => {
    const { userNameFilterText } = this.state;

    const userName = user.name.toLowerCase();
    const search = userNameFilterText.toLowerCase();
    return contains(userName, search);
  }

  userNameFilterChanged = (event) => {
    this.setState({
      userNameFilterText: event.target.value,
    });
  }

  nickNameFilter = (user) => {
    const { nickNameFilterText } = this.state;

    const search = nickNameFilterText.toLowerCase();
    if (!nickNameFilterText) { return true; }

    return user.nicknames.some(nickname => contains(nickname.toLowerCase(), search));
  }

  nickNameFilterChanged = (event) => {
    this.setState({
      nickNameFilterText: event.target.value,
    });
  }

  render() {
    const {
      users,
      isLoading,
    } = this.props;
    const {
      userNameFilterText,
      nickNameFilterText,
    } = this.state;

    if (!users) {
      return null;
    }

    const usersWithKeys = addKeysToObjects(users);

    const items = usersWithKeys.filter(this.userNameFilter)
      .filter(this.nickNameFilter)
      .map(user => <User key={user.id} user={user} />);

    const headerRow = (
      <TableRow>
        <SearchableHeaderColumn hintText="Player search" value={userNameFilterText} onChange={this.userNameFilterChanged} />
        <SearchableHeaderColumn hintText="Nickname search" value={nickNameFilterText} onChange={this.nickNameFilterChanged} />
      </TableRow>
    );

    return (
      <SearchableTable id="user-list" headerRow={headerRow} isLoading={isLoading}>
        {items || null}
      </SearchableTable>
    );
  }
}
