import { getCurrentUser } from '../selectors/user';
import { commitUser, fetchUserWithTeams } from './user';

// CURRENT USER

export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER';
const saveCurrentUserId = userId => ({
  type: SAVE_CURRENT_USER,
  userId,
});

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
const resetCurrentUserId = () => ({
  type: RESET_CURRENT_USER,
});

export const FINISH_SYNC_CURRENT_USER = 'FINISH_SYNC_CURRENT_USER';
const finishSyncCurrentUser = () => ({
  type: FINISH_SYNC_CURRENT_USER,
});

export const saveCurrentUserAndSyncInfo = auth => async (dispatch, getState) => {
  dispatch(saveCurrentUserId(auth.uid));

  const userNotLoaded = !getCurrentUser(getState());
  if (userNotLoaded) {
    await dispatch(commitUser(auth));
    await dispatch(fetchUserWithTeams(auth.uid));
  }
  return auth.uid;
};

export const syncCurrentUser = auth => async (dispatch) => {
  if (auth) {
    await dispatch(saveCurrentUserAndSyncInfo(auth));
  } else {
    dispatch(resetCurrentUserId());
  }
  dispatch(finishSyncCurrentUser());
  return auth;
};
