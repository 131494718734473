import { reference } from '../database';
import { commitMembership } from './membership';
import { fetchUser } from './user';

// TEAMS

export const REQUEST_TEAMS = 'REQUEST_TEAMS';
const requestTeams = () => ({
  type: REQUEST_TEAMS,
});

export const RECEIVE_TEAMS = 'RECEIVE_TEAMS';
const receiveTeams = teams => ({
  type: RECEIVE_TEAMS,
  teams,
});

export const fetchTeams = () => (dispatch) => {
  dispatch(requestTeams());

  return reference('teams/').once('value').then((teams) => {
    dispatch(receiveTeams(teams.val()));
  });
};

// TEAM - COMMIT

export const SEND_TEAM = 'SEND_TEAM';
const sendTeam = team => ({
  type: SEND_TEAM,
  team,
});

export const DELIVER_TEAM = 'DELIVER_TEAM';
const deliverTeam = (teamId, team) => ({
  type: DELIVER_TEAM,
  teamId,
  team,
});

export const commitTeam = (teamName, teamTag, creatorUserId) => (dispatch) => {
  const team = {
    name: teamName,
    tag: teamTag,
    creator: creatorUserId,
  };

  dispatch(sendTeam(team));

  const transaction = reference('teams').push();
  return transaction.set(team).then(() => {
    dispatch(deliverTeam(transaction.key, team));
    return transaction.key;
  });
};

// TEAM - FETCH

export const REQUEST_TEAM = 'REQUEST_TEAM';
const requestTeam = teamId => ({
  type: REQUEST_TEAM,
  teamId,
});

export const RECEIVE_TEAM = 'RECEIVE_TEAM';
const receiveTeam = (teamId, team) => ({
  type: RECEIVE_TEAM,
  teamId,
  team,
});

export const fetchTeam = teamId => (dispatch) => {
  dispatch(requestTeam(teamId));

  return reference(`teams/${teamId}`).once('value').then((team) => {
    dispatch(receiveTeam(team.key, team.val()));
    return team;
  });
};

export const fetchTeamWithUsers = teamId => async (dispatch) => {
  dispatch(requestTeam(teamId));

  const team = await dispatch(fetchTeam(teamId));

  const promises = Object.keys(team.val().members || {}).map(userId => dispatch(fetchUser(userId)));
  return Promise.all(promises);
};

export const REQUEST_TEAM_BY_NAME = 'REQUEST_TEAM_BY_NAME';
const requestTeamByName = teamName => ({
  type: REQUEST_TEAM_BY_NAME,
  teamName,
});

export const fetchTeamByName = teamName => (dispatch) => {
  dispatch(requestTeamByName(teamName));

  return reference('teams').orderByChild('name').equalTo(teamName).limitToFirst(1)
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        const snapshotVal = snapshot.val();
        const teamId = Object.keys(snapshotVal)[0];
        const team = snapshotVal[teamId];

        dispatch(receiveTeam(teamId, team));
      }
      return snapshot;
    });
};

export const linkOrCreateTeam = (userId, teamName, teamTag) => (dispatch) => {
  dispatch(fetchTeamByName(teamName)).then((snapshot) => {
    if (snapshot.exists()) {
      const snapshotVal = snapshot.val();
      const teamId = Object.keys(snapshotVal)[0];

      dispatch(commitMembership(userId, teamId));
    } else {
      dispatch(commitTeam(teamName, teamTag, userId)).then((teamId) => {
        dispatch(commitMembership(userId, teamId));
      });
    }
  });
};

// TEAM - DELETE

export const START_TEAM_DELETE = 'START_TEAM_DELETE';
const startDeletingTeam = teamId => ({
  type: START_TEAM_DELETE,
  teamId,
});

export const FINISH_TEAM_DELETE = 'FINISH_TEAM_DELETE';
const finishDeletingTeam = teamId => ({
  type: FINISH_TEAM_DELETE,
  teamId,
});

export const deleteTeam = teamId => (dispatch) => {
  dispatch(startDeletingTeam(teamId));

  return reference(`teams/${teamId}`).remove().then(() => {
    dispatch(finishDeletingTeam(teamId));
  });
};
