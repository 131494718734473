import React from 'react';
import Grid from '@material-ui/core/Grid';
import * as firebase from 'firebase';

import { AddNick } from './AddNickContainer';
import { AddUnreal } from './AddUnrealContainer';
import { Page } from '../Shared/Page';
import { LinkTeam } from './LinkTeamContainer';
import { UserStatus } from './UserStatus';
import { Loader } from '../Shared/Loader';
import { UpdateFacebookLink } from './UpdateFacebookLinkContainer';

export class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    firebase.auth().onAuthStateChanged(props.syncCurrentUser);
  }

  render() {
    const { userLoaded, userIsLoggedIn, currentUser } = this.props;

    if (!userLoaded) {
      return (<Page>
        <Grid container spacing={2}>
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      </Page>
      );
    }

    if (!userIsLoggedIn) {
      return (
        <Page>
          <Grid container spacing={2}>
            <UserStatus user={currentUser} userLoaded={userLoaded} />
          </Grid>
        </Page>
      );
    }

    return (
      <Page>
        <Grid container wrap="nowrap" justify="space-between" spacing={3}>
          <UserStatus user={currentUser} userLoaded={userLoaded} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AddNick />
          </Grid>
          <Grid item xs={12}>
            <LinkTeam />
          </Grid>
          <Grid item xs={12}>
            <UpdateFacebookLink />
          </Grid>
          <Grid item>
            <AddUnreal />
          </Grid>
        </Grid>
      </Page>
    );
  }
}
