import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TextField from '@material-ui/core/TextField';

import { TablePagination } from './TablePagination';
import { Loader } from './Loader';

const LoadingRow = () => (
  <TableRow key="0">
    <TableCell className="loading">
      <Loader />
    </TableCell>
  </TableRow>
);

export class SearchableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPerPage: 10,
      currentPage: 1,
    };
  }

  render() {
    const {
      currentPage,
      itemsPerPage,
    } = this.state;
    const {
      children,
      isLoading,
      id,
      headerRow,
    } = this.props;

    const totalPages = Math.ceil(children.length / itemsPerPage);
    const startingIndex = (currentPage - 1) * itemsPerPage;
    const endingIndex = startingIndex + itemsPerPage;
    const displayedChildren = children.slice(startingIndex, endingIndex);
    const content = (isLoading ? <LoadingRow /> : displayedChildren);

    return (
      <Paper id={id}>
        <Table>
          <TableHead>
            {headerRow}
          </TableHead>
          <TableBody>
            {content}
          </TableBody>
          {totalPages > 1
            ? (
              <TableFooter style={{ borderCollapse: 'separate' }} className="footer">
                <TableRow>
                  <TableCell>
                    <TablePagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onChange={value => this.setState({ currentPage: value })}
                      hidePreviousAndNextPageLinks
                      hideFirstAndLastPageLinks
                      hideEllipsis
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )
            : null}
        </Table>
      </Paper>
    );
  }
}

export const SearchableHeaderColumn = ({ hintText, value, onChange }) => (
  <TableCell style={{ width: '50%' }}>
    <TextField
      placeholder={hintText}
      value={value}
      onChange={onChange}
      style={{ width: 'auto' }}
      margin="dense"
    />
  </TableCell>
);
