import React from 'react';
import ChipInput from 'material-ui-chip-input';
import { StartAddornment } from '../Shared/StartAddornment';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

export const AddNick = (props) => {
  return (
    <StartAddornment addornment={<PersonOutlineIcon className="addornment-near-chip-input" />}>
      <ChipInput
        value={props.userNicknames}
        onAdd={(nick) => props.addNickname(nick)}
        onDelete={(nick) => props.deleteNickname(nick)}
        label="Manage your nicknames"
        placeholder="Add your nickname"
        alwaysShowPlaceholder={props.userNicknames && props.userNicknames.length > 0}
        margin="normal"
        style={{ minWidth: 200 }}
      />
    </StartAddornment>
  )
};
