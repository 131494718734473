import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as firebase from 'firebase';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { firebaseConfig } from 'config';
import { rootReducer } from './reducers';
import { Overview } from './features/Overview/OverviewContainer';
import { UserCard } from './features/UserCard/UserCardContainer';
import { TeamCard } from './features/TeamCard/TeamCardContainer';
import { About } from './features/About/About';
import { UserProfile } from './features/UserProfile/UserProfileContainer';
import { Login } from './features/Login/Login';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
const store = createStore(rootReducer, enhancer);

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Overview} />
        <Route path="/player/:userId" component={UserCard} />
        <Route path="/clan/:teamId" component={TeamCard} />
        <Route path="/about/" component={About} />
        <Route path="/my-profile/" component={UserProfile} />
        <Route path="/login/" component={Login} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
