export const unrealVersions = [
  {
    value: 0, group: 'U', name: 'Unreal', shortName: 'Unreal',
  },
  {
    value: 1, group: 'UT', name: 'Unreal Tournament', shortName: 'UT99',
  },
  {
    value: 2, group: 'UC', name: 'Unreal Championship', shortName: 'UC',
  },
  {
    value: 3, group: 'UT', name: 'Unreal Tournament 2003', shortName: 'UT2k3',
  },
  {
    value: 4, group: 'U', name: 'Unreal II: The Awakening', shortName: 'Unreal 2',
  },
  {
    value: 5, group: 'UT', name: 'Unreal Tournament 2004', shortName: 'UT2k4',
  },
  {
    value: 6, group: 'UC', name: 'Unreal Championship 2: The Liandri Conflict', shortName: 'UC2',
  },
  {
    value: 7, group: 'UT', name: 'Unreal Tournament 3', shortName: 'UT3',
  },
  {
    value: 8, group: 'UT', name: 'Unreal Tournament 4', shortName: 'UT4',
  },
];
