import React from 'react';
import Link from '@material-ui/core/Link';

const getURLParameter = (name) => {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) ||
    [null, ''])[1].replace(/\+/g, '%20')) || null;
}

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ''
    };
  }

  setContent = (content) => {
    this.setState({ content });
  }

  async componentDidMount() {
    console.log(process.env.firebaseFunctionsUri)

    const code = getURLParameter('code');
    const state = getURLParameter('state');
    const error = getURLParameter('error');

    if (error) {
      this.setContent(`Error back from the Discord auth page: ${error}`);
    } else if (!code) {
      this.setContent(`Error back from the Discord auth page: No code`);
    } else {
      const tokenFunctionURL = `${process.env.firebaseFunctionsUri}/token?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}`;

      try {
        const response = await fetch(tokenFunctionURL, {
          method: "GET",
          credentials: 'include'
        });
        if (!response.ok) {
          this.setContent(`Not OK: ${response.status}, Body ${await response.text()}`);
          return;
        }
      }
      catch (error) {
        this.setContent(`Error: ${error}`);
      }
    }
  }

  render() {
    return (
      <div>
        <span>Content: {this.state.content}</span>
        <Link href={`${process.env.firebaseFunctionsUri}/redirect`}>
          Link
        </Link>
      </div>);
  }
}