import { combineReducers } from 'redux';

import { REQUEST_USERS, RECEIVE_USERS, RECEIVE_USER } from '../actions/user';
import {
  REQUEST_TEAMS, RECEIVE_TEAMS, RECEIVE_TEAM, DELIVER_TEAM, FINISH_TEAM_DELETE,
} from '../actions/team';
import { DELIVER_MEMBERSHIP, FINISH_MEMBERSHIP_DELETE } from '../actions/membership';
import { DELIVER_NICKNAME, FINISH_NICKNAME_DELETE } from '../actions/nickname';
import { DELIVER_UNREAL, FINISH_UNREAL_DELETE } from '../actions/unreal';
import { DELIVER_FACEBOOK_LINK } from '../actions/facebookLink';
import { SAVE_CURRENT_USER, RESET_CURRENT_USER, FINISH_SYNC_CURRENT_USER } from '../actions/currentuser';

const readProperty = (copy, collectionName, parseInt) => {
  const collection = copy[collectionName];
  if (collection === undefined) {
    copy[collectionName] = [];
  } else {
    copy[collectionName] = parseInt ? Object.keys(collection).map(Number) : Object.keys(collection);
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_USERS:
    case RECEIVE_USER: {
      const copy = Object.assign({}, state);
      readProperty(copy, 'nicknames');
      readProperty(copy, 'teams');
      readProperty(copy, 'unreals', true);
      return copy;
    }
    case DELIVER_NICKNAME:
      return {
        ...state,
        nicknames: [
          ...state.nicknames,
          action.nickname,
        ],
      };
    case FINISH_NICKNAME_DELETE: {
      const copy = Object.assign({}, state);
      copy.nicknames = copy.nicknames.filter(nickname => nickname !== action.nickname);
      return copy;
    }
    case DELIVER_FACEBOOK_LINK: {
      return {
        ...state,
        facebookProfile: action.link
      }
    }
    case DELIVER_MEMBERSHIP:
      return {
        ...state,
        teams: [
          ...state.teams,
          action.teamId,
        ],
      };
    case FINISH_MEMBERSHIP_DELETE: {
      const copy = Object.assign({}, state);
      copy.teams = copy.teams.filter(team => team !== action.teamId);
      return copy;
    }
    case DELIVER_UNREAL:
      return {
        ...state,
        unreals: [
          ...state.unreals,
          action.unreal,
        ],
      };
    case FINISH_UNREAL_DELETE: {
      const copy = Object.assign({}, state);
      copy.unreals = copy.unreals.filter(unreal => unreal !== action.unreal);
      return copy;
    }
    default:
      return state;
  }
};

const team = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_TEAMS:
    case RECEIVE_TEAM:
    case DELIVER_TEAM: {
      const copy = Object.assign({}, state);
      readProperty(copy, 'members');
      return copy;
    }
    case DELIVER_MEMBERSHIP:
      return {
        ...state,
        members: [
          ...state.members,
          action.userId,
        ],
      };
    case FINISH_MEMBERSHIP_DELETE: {
      const copy = Object.assign({}, state);
      copy.members = copy.members.filter(u => u !== action.userId);
      return copy;
    }
    default:
      return state;
  }
};

const users = (state = { isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case REQUEST_USERS:
      return { ...state, isFetching: true };
    case RECEIVE_USERS: {
      for (const key in action.users) {
        action.users[key] = user(action.users[key], action);
      }
      return { ...state, isFetching: false, items: { ...action.users } };
    }
    case RECEIVE_USER:
      return { ...state, items: { ...state.items, [action.userId]: user(action.user, action) } };
    case DELIVER_NICKNAME:
    case FINISH_NICKNAME_DELETE:
    case DELIVER_MEMBERSHIP:
    case FINISH_MEMBERSHIP_DELETE:
    case DELIVER_UNREAL:
    case FINISH_UNREAL_DELETE:
    case DELIVER_FACEBOOK_LINK:
      return { 
        ...state,
        items: {
          ...state.items,
          [action.userId]: user(state.items[action.userId],
          action)
        }
      };
    default:
      return state;
  }
};

const teams = (state = { isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case REQUEST_TEAMS:
      return { ...state, isFetching: true };
    case RECEIVE_TEAMS: {
      for (const key in action.teams) {
        action.teams[key] = team(action.teams[key], action);
      }
      return { ...state, isFetching: false, items: { ...action.teams } };
    }
    case RECEIVE_TEAM:
    case DELIVER_TEAM:
      return { ...state, items: { ...state.items, [action.teamId]: team(action.team, action) } };
    case FINISH_TEAM_DELETE: {
      const { [action.teamId]: deletedTeam, ...rest } = state.items;
      return { ...state, items: rest };
    }
    case DELIVER_MEMBERSHIP:
    case FINISH_MEMBERSHIP_DELETE:
      return { ...state, items: { ...state.items, [action.teamId]: team(state.items[action.teamId], action) } };
    default:
      return state;
  }
};

const currentUser = (state = { id: null, isFetching: true }, action) => {
  switch (action.type) {
    case SAVE_CURRENT_USER:
      return { ...state, id: action.userId };
    case RESET_CURRENT_USER:
      return { ...state, id: null };
    case FINISH_SYNC_CURRENT_USER:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};


export const rootReducer = combineReducers({
  teams,
  users,
  currentUser,
});
