import { connect } from 'react-redux';

import { fetchUserWithTeams } from '../../actions/user';
import { UserCard as UserCardComponent } from './UserCard';
import { getUserTeams, getUserUnrealNames } from '../../selectors/user';

const mapStateToProps = (state, ownProps) => {
  const user = state.users.items[ownProps.match.params.userId];
  const teams = getUserTeams(state, user);
  return {
    user,
    userTeams: teams,
    userUnreals: getUserUnrealNames(user),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUser: () => dispatch(fetchUserWithTeams(ownProps.match.params.userId)),
});

export const UserCard = connect(mapStateToProps, mapDispatchToProps)(UserCardComponent);
