import React from 'react';
import Launch from '@material-ui/icons/Launch';
import grey from '@material-ui/core/colors/grey';

export const OuterLink = ({ href, children, icon }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
    {icon || <Launch color="action" className="icon" />}
  </a>
);
