import React from 'react';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase';

export class FacebookLogin extends React.Component {
  logInFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  render() {
    return (
      <Button label="Link me by Facebook" color="primary" variant="contained" onClick={this.logInFacebook} id="facebook-login">
        Link me by Facebook
      </Button>
    );
  }
}
