import Grid from '@material-ui/core/Grid';

export const StartAddornment = (props) => (
  <Grid container spacing={1} alignItems="flex-end">
    <Grid item>
      <div style={{ marginRight: "12px" }}>
        {props.addornment}
      </div>
    </Grid>
    <Grid item>
      {props.children}
    </Grid>
  </Grid>
)