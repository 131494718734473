import { connect } from 'react-redux';

import { syncCurrentUser } from '../../actions/currentuser';
import { UserProfile as UserProfileComponent } from './UserProfile';
import { getCurrentUser } from '../../selectors/user';

const mapStateToProps = state => ({
  userLoaded: !state.currentUser.isFetching,
  userIsLoggedIn: !!state.currentUser.id,
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  syncCurrentUser: async (auth) => {
    await dispatch(syncCurrentUser(auth));
  },
});

export const UserProfile = connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);
