import React from 'react';
import { Grid } from '@material-ui/core';

import { Page } from '../Shared/Page';
import { LinkedUserAvatar } from '../Shared/UserAvatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export class TeamCard extends React.Component {
  componentDidMount() {
    this.props.fetchTeam();
  }

  render() {
    const {
      team,
      members
    } = this.props;

    if (team) {
      return (
        <Page>
          <Grid container direction="column">
            <Grid item>
              <b>{team.tag} {team.name}</b>
            </Grid>
            <Grid item>
              <List dense>
                {members.map(member => (<ListItem key={member.id}><LinkedUserAvatar user={member} /></ListItem>))}
              </List>
            </Grid>
          </Grid>
        </Page>
      );
    }

    return null;
  }
}
