import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import { StartAddornment } from '../Shared/StartAddornment';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';

const filter = createFilterOptions({ limit: 10 });

export class LinkTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogNewTeamTag: '',
      dialogNewTeamName: '',
      dialogOpen: false
    };
  }

  handleClose = () => {
    this.setState({
      dialogNewTeamTag: '',
      dialogNewTeamName: '',
      dialogOpen: false
    });
  };

  handleAddClanSubmit = (event) => {
    event.preventDefault();

    this.props.linkUserWithTeam(this.state.dialogNewTeamName, this.state.dialogNewTeamTag);

    this.handleClose();
  }

  componentDidMount() {
    this.props.fetchTeams();
  }

  transformTeamToOption = (team) => {
    return {
      tag: team.tag,
      name: team.name
    };
  }

  teamNameChanged = (event, values, type) => {
    if (type === "create-option") {
      const newValue = values[values.length - 1];
      this.openDialogWindow(newValue);
    }
    else if (type === "select-option" && values[values.length - 1].isCreatingOption) {
      const newValue = values[values.length - 1];
      this.openDialogWindow(newValue.name);
    }
    else if (type === "select-option") {
      const newValue = values[values.length - 1];
      this.props.linkUserWithTeam(newValue.name, newValue.tag);
    }
    else if (type === "remove-option") {
      const previousTeamNames = this.props.userTeams.map(this.transformTeamToOption).map(t => t.name);
      const currentTeamNames = values.map(t => t.name);

      const deletedTeamNames = previousTeamNames.filter(teamName => !currentTeamNames.includes(teamName));

      deletedTeamNames.forEach(value => this.props.unlinkUserFromTeam(value))
    }
  }

  openDialogWindow = (teamName) => {
    this.setState({
      dialogNewTeamName: teamName,
      dialogOpen: true
    });
  }

  getOptionLabel = option => {
    return `${option.tag} ${option.name}`;
  }

  renderOption = (option, state) => {
    if (option.isCreatingOption) {
      return option.title;
    }

    return (<span>{option.tag} | {option.name}</span>);
  }

  addCustomValueOption = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.unshift({
        title: `Add new clan "${params.inputValue}"`,
        name: params.inputValue,
        isCreatingOption: true
      });
    }
    else {
      filtered.unshift({
        title: `Add new clan`,
        name: params.inputValue,
        isCreatingOption: true
      });
    }

    return filtered;
  }

  render() {
    return (
      <React.Fragment>
        <Autocomplete
          freeSolo
          multiple
          disableClearable
          filterSelectedOptions
          getOptionSelected={(option, value) => option.name === value.name}
          renderOption={this.renderOption}
          renderTags={(values, getTagProps) => values.map((value, index) =>
            <Chip key={value.name} {...getTagProps({ index })} label={<span>{value.tag} | {value.name}</span>} />)}
          options={this.props.teams.map(this.transformTeamToOption)}
          getOptionLabel={this.getOptionLabel}
          filterOptions={this.addCustomValueOption}
          onChange={this.teamNameChanged}
          value={this.props.userTeams.map(this.transformTeamToOption)}
          renderInput={(params) => (
            <StartAddornment addornment={<PeopleAltRoundedIcon className="addornment-near-chip-input" />}>
            <TextField
              {...params}
              label="Manage your clans"
              placeholder="Add your clan"
              margin="normal"
              style={{ minWidth: 200 }}
              fullWidth={false}
            />
            </StartAddornment>
          )}
          id="team-linking"
        />
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={this.handleAddClanSubmit}>
            <DialogTitle id="form-dialog-title">Add a new clan</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="tag"
                defaultValue={this.state.dialogNewTeamTag}
                onChange={(event) => this.setState({ dialogNewTeamTag: event.target.value })}
                label="Clan tag"
                type="text"
              />
              <TextField
                margin="dense"
                id="name"
                defaultValue={this.state.dialogNewTeamName}
                onChange={(event) => this.setState({ dialogNewTeamName: event.target.value })}
                label="Clan name"
                type="text"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">Cancel</Button>
              <Button type="submit" color="primary">Add</Button>
            </DialogActions>
          </form>
        </Dialog>
        </React.Fragment>
    );
  }
}
