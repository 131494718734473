import { connect } from 'react-redux';

import { UpdateFacebookLink as UpdateFacebookLinkComponent } from './UpdateFacebookLink';
import { updateFacebookLink } from '../../actions/facebookLink';
import { getCurrentUser, getUserFacebookLink } from '../../selectors/user';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUserId: state.currentUser.id,
    userFacebookLink: getUserFacebookLink(currentUser),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { currentUserId } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateFacebookLink: (link) => {
      dispatch(updateFacebookLink(currentUserId, link));
    }
  };
};

export const UpdateFacebookLink = connect(mapStateToProps, null, mergeProps)(UpdateFacebookLinkComponent);