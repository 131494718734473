import { reference } from '../database';
import { fetchTeam } from './team';

// USERS

export const REQUEST_USERS = 'REQUEST_USERS';
const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const RECEIVE_USERS = 'RECEIVE_USERS';
const receiveUsers = users => ({
  type: RECEIVE_USERS,
  users,
});

export const fetchUsers = () => (dispatch) => {
  dispatch(requestUsers());

  reference('users/').once('value').then((users) => {
    dispatch(receiveUsers(users.val()));
  });
};

// USER - FETCH

export const REQUEST_USER = 'REQUEST_USER';
const requestUser = userId => ({
  type: REQUEST_USER,
  userId,
});


export const RECEIVE_USER = 'RECEIVE_USER';
const receiveUser = (userId, user) => ({
  type: RECEIVE_USER,
  userId,
  user,
});

export const fetchUser = userId => async (dispatch) => {
  dispatch(requestUser(userId));

  const user = await reference(`users/${userId}`).once('value');
  dispatch(receiveUser(user.key, user.val()));
  return user;
};

export const fetchUserWithTeams = userId => async (dispatch) => {
  dispatch(requestUser(userId));

  const user = await dispatch(fetchUser(userId));

  const promises = Object.keys(user.val().teams || {}).map(teamId => dispatch(fetchTeam(teamId)));
  return Promise.all(promises);
};

// USER - COMMIT

export const SEND_USER = 'SEND_USER';
const sendUser = (userId, user) => ({
  type: SEND_USER,
  userId,
  user,
});

export const commitUser = auth => (dispatch) => {

  const userId = auth.uid;
  const user = getUser(auth);
  
  dispatch(sendUser(userId, user));

  return reference(`users/${userId}`).update(user);
};

const getUser = auth => {
  const user = {
    name: auth.displayName,
    email: auth.email
  };

  const providerObjects = auth.providerData.map(profile => {
    if(profile.providerId === "facebook.com") {
      return extractFacebookData(profile);
    }
    else {
      console.warn(`Unexpected provider: ${JSON.stringify(profile)}`);
    }
  });

  return Object.assign(user, ...providerObjects);
}

const extractFacebookData = (providerData) => {
  return {
    "facebook": {
      userId: providerData.uid,
      avatar: providerData.photoURL
    }
  }
}