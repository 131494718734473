import React from 'react';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase';

export class DiscordLogin extends React.Component {
  render() {
    return null;

    return (
      <Button label="Link me by Discord" color="primary" variant="contained" href={`${process.env.firebaseFunctionsUri}/redirect`} id="discord-login">
        Link me by Discord
      </Button>
    );
  }
}
