import { connect } from 'react-redux';

import { AddNick as AddNickComponent } from './AddNick';
import { commitNickname, deleteNickname } from '../../actions/nickname';
import { getUserNickNames, getCurrentUser } from '../../selectors/user';
import { contains } from '../../util';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUserId: state.currentUser.id,
    currentUser,
    userIsLoggedIn: !!currentUser,
    userNicknames: getUserNickNames(state, currentUser),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { userNicknames, currentUserId } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    addNickname: (nickname) => {
      if (!contains(userNicknames, nickname)) { dispatch(commitNickname(currentUserId, nickname)); }
    },
    deleteNickname: (nickname) => {
      if (contains(userNicknames, nickname)) dispatch(deleteNickname(currentUserId, nickname));
    }
  };
};

export const AddNick = connect(mapStateToProps, null, mergeProps)(AddNickComponent);
