import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';

import { Header } from './Header';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      ...indigo,
    },
  },
});

export const Page = ({ className, children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <div>
      <Header />
      <div className={className} style={{ padding: '24px' }}>
        {children}
      </div>
    </div>
  </MuiThemeProvider>
);
