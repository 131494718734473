import React from 'react';
import Avatar from '@material-ui/core/Avatar';


export const FacebookPhoto = ({ user, width }) => {
  const facebookAvatarUri = user && (user.photoURL || (user.facebook.avatar));

  if (!facebookAvatarUri) {
    return null;
  }

  const photoUrl = `${facebookAvatarUri}?width=${width}`;
  return (<Avatar alt={user.name} src={photoUrl} variant="rounded" className="profile-picture" style={{
    width,
    height: "auto",
    marginRight: "12px"
  }} />);
};
