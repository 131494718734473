import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';

import { SearchableTable, SearchableHeaderColumn } from '../Shared/SearchableTable';
import { contains, addKeysToObjects } from '../../util';

const Team = ({ team }) => (
  <TableRow>
    <TableCell>
      <Link to={`/clan/${team.id}`}>
        {team.name}
      </Link>
    </TableCell>
    <TableCell>
      {team.tag}
    </TableCell>
  </TableRow>
);

export class TeamList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamNameFilterText: '',
      teamTagFilterText: '',
    };
  }

  teamNameFilter = (team) => {
    const { teamNameFilterText } = this.state;

    const teamName = team.name.toLowerCase();
    const search = teamNameFilterText.toLowerCase();
    return contains(teamName, search);
  }

  teamTagFilter = (team) => {
    const { teamTagFilterText } = this.state;

    if (!teamTagFilterText) { return true; }

    if (!team.tag) { return false; }

    const teamTag = team.tag.toLowerCase();
    const search = teamTagFilterText.toLowerCase();
    return contains(teamTag, search);
  }

  teamNameFilterChanged = (event) => {
    this.setState({
      teamNameFilterText: event.target.value,
    });
  }

  teamTagFilterChanged = (event) => {
    this.setState({
      teamTagFilterText: event.target.value,
    });
  }

  render() {
    const { teams, isLoading } = this.props;
    const {
      teamNameFilterText,
      teamTagFilterText,
    } = this.state;

    if (!teams) {
      return null;
    }

    const teamsWithKeys = addKeysToObjects(teams);

    const items = teamsWithKeys.filter(this.teamNameFilter)
      .filter(this.teamTagFilter)
      .map(team => <Team key={team.id} team={team} />);

    const headerRow = (
      <TableRow>
        <SearchableHeaderColumn hintText="Clan name search" value={teamNameFilterText} onChange={this.teamNameFilterChanged} />
        <SearchableHeaderColumn hintText="Clan tag search" value={teamTagFilterText} onChange={this.teamTagFilterChanged} />
      </TableRow>
    );

    return (
      <SearchableTable id="team-list" headerRow={headerRow} isLoading={isLoading}>
        {items}
      </SearchableTable>);
  }
}
