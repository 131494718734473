import React from 'react';
import Grid from '@material-ui/core/Grid';

import { UserList } from './Userlist';
import { TeamList } from './Teamlist';
import { Page } from '../Shared/Page';

export class Overview extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchTeams();
  }

  render() {
    const {
      usersFetching,
      teamsFetching,
      users,
      teams,
    } = this.props;

    return (
      <Page>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <UserList users={users} isLoading={usersFetching} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TeamList teams={teams} isLoading={teamsFetching} />
          </Grid>
        </Grid>
      </Page>
    );
  }
}
