import React from 'react';

import { OuterLink } from '../Shared/OuterLink';
import { getUserFacebookLink } from '../../selectors/user';

const facebookIcon = <img alt="Facebook" className="icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABZUlEQVQ4jZWRX2uBYRiHn8+g9g0cSA6UOFA071Dmv3k3ZCmWvcKypNZilAMpB/4sKUqaFzNbS8qpnTjjC2wnO96H+O3okd54N1ddB89199wnN2EjHYk/NV76UhMcoj81XrKRjoSwCX7J5WfINRZ4ePz4l7nGAlx+BjbBL4mLGyJWnOPyfnqQseIcLm4IYov2Ebh7/9Pz9AgOrgUH19o0W7QPYgn3cJF5E/UsNcDX9w8otFvCPRBTqAtv+lXUz63PADbdFOqCGIJtuG5fRKUIuyHYBtH7WrDfPO+UCdXBhOqbBfRN53pfC0TLNnGaGO10H3SuZZsgGk8d5thgp/ugc42nDqJyVnFyzYtKEXaVswqitFZwfPUkKkXYldYKiMJSRiA3FZUi7ApLGURuLsEe5+HNTPa6uf9Ws8d5yM0lEJmxsFK7a6JLhAvscR5qdw0yY2FFpLrkkZTJrqWGPA6Sya6luuTRLyuVjVsi3cXkAAAAAElFTkSuQmCC" />;

export const FacebookProfileLink = (props) => {
  const userFacebookUrl = getUserFacebookLink(props.user);
  if (!!userFacebookUrl) {
    return (
      <OuterLink href={userFacebookUrl} icon={facebookIcon}>
        {props.children}
      </OuterLink>
    )
  }

  return (<React.Fragment>{props.children}</React.Fragment>);
}