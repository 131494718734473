import { connect } from 'react-redux';

import { fetchTeamWithUsers } from '../../actions/team';
import { TeamCard as TeamCardComponent } from './TeamCard';
import { getTeamUsers } from '../../selectors/team';

const mapStateToProps = (state, ownProps) => {
  const team = state.teams.items[ownProps.match.params.teamId];
  const users = getTeamUsers(state, team);
  return {
    team,
    members: users,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTeam: () => dispatch(fetchTeamWithUsers(ownProps.match.params.teamId)),
});

export const TeamCard = connect(mapStateToProps, mapDispatchToProps)(TeamCardComponent);
