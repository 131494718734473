import React from 'react';
import Button from '@material-ui/core/Button';

const ReactUltimatePagination = require('react-ultimate-pagination');

const Page = ({ isActive, onClick, value }) => {
  const activeStyle = isActive ? { minWidth: 'auto', fontWeight: '900' } : { minWidth: 'auto' };
  return (
    <Button
      onClick={onClick}
      style={activeStyle}
    >
      {value}
    </Button>
  );
};

const Wrapper = ({ children }) => (
  <div className="pagination">
    {children}
  </div>
);

const itemTypeToComponent = {
  PAGE: Page,
};

export const TablePagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent: Wrapper,
});
