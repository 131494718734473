import { reference } from '../database';

// NICKNAME - COMMIT

export const SEND_NICKNAME = 'SEND_NICKNAME';
const sendNickname = (userId, nickname) => ({
  type: SEND_NICKNAME,
  userId,
  nickname,
});

export const DELIVER_NICKNAME = 'DELIVER_NICKNAME';
const deliverNickname = (userId, nickname) => ({
  type: DELIVER_NICKNAME,
  userId,
  nickname,
});

export const commitNickname = (userId, nickname) => (dispatch) => {
  dispatch(sendNickname(userId, nickname));

  return reference(`users/${userId}/nicknames/${nickname}`).set(true).then(() => {
    dispatch(deliverNickname(userId, nickname));
  });
};

// NICKNAME - DELETE

export const START_NICKNAME_DELETE = 'START_NICKNAME_DELETE';
const startDeletingNickname = (userId, nickname) => ({
  type: START_NICKNAME_DELETE,
  userId,
  nickname,
});

export const FINISH_NICKNAME_DELETE = 'FINISH_NICKNAME_DELETE';
const finishDeletingNickname = (userId, nickname) => ({
  type: FINISH_NICKNAME_DELETE,
  userId,
  nickname,
});

export const deleteNickname = (userId, nickname) => (dispatch) => {
  dispatch(startDeletingNickname(userId, nickname));

  return reference(`users/${userId}/nicknames/${nickname}`).remove().then(() => {
    dispatch(finishDeletingNickname(userId, nickname));
  });
};
