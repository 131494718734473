import React, { Fragment } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { contains } from '../../util';
import { unrealVersions } from '../../constants';

const Unreal = ({ checked, onChange, name }) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} />}
    label={name}
  />);

export class AddUnreal extends React.Component {
  handleChange = (unrealValue, checked) => {
    const {
      commitUnreal,
      deleteUnreal,
    } = this.props;

    if (checked) {
      commitUnreal(unrealValue);
    } else {
      deleteUnreal(unrealValue);
    }
  }

  getUnrealItem = (unreal) => {
    const { userUnreals } = this.props;

    const inChecked = contains(userUnreals, unreal.value);
    return (
      <Unreal
        key={unreal.value}
        value={unreal.value}
        name={unreal.name}
        checked={inChecked}
        onChange={(event, checked) => this.handleChange(unreal.value, checked)}
        disableRipple
      />
    );
  }

  render() {
    const uts = unrealVersions.filter(unreal => unreal.group === 'UT').map(this.getUnrealItem);
    const unreals = unrealVersions.filter(unreal => unreal.group === 'U').map(this.getUnrealItem);
    const ucs = unrealVersions.filter(unreal => unreal.group === 'UC').map(this.getUnrealItem);
    return (
      <React.Fragment>
        <div className="setting-name">
          Unreals you enjoyed playing:
        </div>
        <div className="unreal-listing">
          <FormGroup className="unreal-column">
            {uts}
          </FormGroup>
          <FormGroup className="unreal-column">
            {unreals}
          </FormGroup>
          <FormGroup className="unreal-column">
            {ucs}
          </FormGroup>
        </div>
      </React.Fragment>
    );
  }
}
