export const addKeysToObjects = collection => Object.keys(collection).map((key) => {
  const object = collection[key];
  object.id = key;
  return object;
});

export const joinAndAddKeys = (keys, collection) =>
  keys.map(key => {
    const item = collection[key];
    if (item) {
      item.id = key;
      return item;
    }
  })
  .filter(item => item);

export const contains = (array, object) => array.indexOf(object) !== -1;
