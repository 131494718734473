import { unrealVersions } from '../constants/index';
import { joinAndAddKeys } from '../util';

export const getUserTeams = (state, user) => {
  if (user) {
    return joinAndAddKeys(user.teams, state.teams.items);
  }
  return [];
};

export const getCurrentUser = state => state.users.items[state.currentUser.id];

export const getUserNickNames = (state, user) => {
  if (user) {
    return user.nicknames;
  }
  return [];
};

export const getUserUnreals = (user) => {
  if (user) {
    return user.unreals;
  }
  return [];
};

export const getUserUnrealNames = (user) => {
  if (user) {
    return user.unreals.map(u => unrealVersions.find(uv => uv.value === u));
  }
  return [];
};

export const getUserFacebookLink = (user) => {
  if (user) {
    return user.facebookProfile;
  }
  return null;
}
