import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import { Page } from '../Shared/Page';
import { OuterLink } from '../Shared/OuterLink';

export const About = () => (
  <Page>
    <Paper id="about">
      <h3>What is this</h3>
      <p>Named after <OuterLink href="https://liandri.beyondunreal.com/UMS_Vortex_Rikers">Vortex Rikers</OuterLink>, a star ship that started it all, this website focuses on bringing all Unreal and Unreal Tournament players together.</p>
      <p>It links together your Facebook account and your nickname and clan name, so that you can be easier found by your long lost team-mates or so that you can meet new real-life friends that you've played so many games with.</p>
      <h3>Reliability</h3>
      <p>The system uses a free plan of Google service <OuterLink href="https://firebase.google.com">Firebase</OuterLink> making the monetization as well as reliability issues things of the past.</p>
      <p>In case of domain-expiration, the site is still accessible at <OuterLink href="https://vortex-cd5ea.firebaseapp.com/">Firebase - Vortex</OuterLink>.</p>
      <p>In case of server-error, the database is still accessible at <OuterLink href="https://vortex-cd5ea.firebaseio.com/">Firebase - Vortex database</OuterLink> via REST. E.g. this is <OuterLink href="https://vortex-cd5ea.firebaseio.com/users/o1ZFcCeRoBVknJtB1CsFhxPuiif2.json">Drakir</OuterLink> and this is <OuterLink href="https://vortex-cd5ea.firebaseio.com/teams/-KtaCivL0aSTCe_pjNHP.json">Žádnej Klan</OuterLink>.</p>
      <h3>Privacy</h3>
      <p>All provided Facebook data (your Facebook ID, Facebook photo and your name) is publicly accessible and makes the service possible. Nothing else is collected.</p>
      <h3>Feedback</h3>
      <p>If there's something that you'd like to see or bothers you, contact me <Link to='/player/o1ZFcCeRoBVknJtB1CsFhxPuiif2'>from Vortex</Link> or on Discord.</p>
      <p>– Drakir</p>
    </Paper>
  </Page>
);
