import { reference } from '../database';
import { fetchTeam, deleteTeam } from './team';

// MEMBERSHIP - COMMIT

export const SEND_MEMBERSHIP = 'SEND_MEMBERSHIP';
const sendMembership = (userId, teamId) => ({
  type: SEND_MEMBERSHIP,
  userId,
  teamId,
});

export const DELIVER_MEMBERSHIP = 'DELIVER_MEMBERSHIP';
const deliverMembership = (userId, teamId) => ({
  type: DELIVER_MEMBERSHIP,
  userId,
  teamId,
});

export const commitMembership = (userId, teamId) => (dispatch) => {
  dispatch(sendMembership(userId, teamId));

  const updates = {};
  updates[`teams/${teamId}/members/${userId}`] = true;
  updates[`users/${userId}/teams/${teamId}`] = true;

  return reference().update(updates).then(() => {
    dispatch(deliverMembership(userId, teamId));
  });
};

// MEMBERSHIP - DELETE

export const START_MEMBERSHIP_DELETE = 'START_MEMBERSHIP_DELETE';
const startDeletingMembership = (userId, teamId) => ({
  type: START_MEMBERSHIP_DELETE,
  userId,
  teamId,
});

export const FINISH_MEMBERSHIP_DELETE = 'FINISH_MEMBERSHIP_DELETE';
const finishDeletingMembership = (userId, teamId) => ({
  type: FINISH_MEMBERSHIP_DELETE,
  userId,
  teamId,
});

export const deleteMembership = (userId, teamId) => (dispatch) => {
  dispatch(startDeletingMembership(userId, teamId));

  const updates = {};
  updates[`teams/${teamId}/members/${userId}`] = null;
  updates[`users/${userId}/teams/${teamId}`] = null;

  return reference().update(updates).then(() => {
    dispatch(finishDeletingMembership(userId, teamId));
    return teamId;
  });
};

export const deleteMembershipAlongTeam = (userId, teamId) => (dispatch) => {
  dispatch(deleteMembership(userId, teamId)).then((_) => {
    dispatch(fetchTeam(teamId)).then((teamSnapshot) => {
      const team = teamSnapshot.val();
      if (!team.members) {
        dispatch(deleteTeam(teamId));
      }
    });
  });
};
