import { connect } from 'react-redux';

import { fetchUsers } from '../../actions/user';
import { fetchTeams } from '../../actions/team';
import { Overview as OverviewComponent } from './Overview';

const mapStateToProps = state => ({
  users: state.users.items,
  teams: state.teams.items,
  usersFetching: state.users.isFetching,
  teamsFetching: state.teams.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => {
    dispatch(fetchUsers());
  },
  fetchTeams: () => {
    dispatch(fetchTeams());
  },
});

export const Overview = connect(mapStateToProps, mapDispatchToProps)(OverviewComponent);
