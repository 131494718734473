import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import { StartAddornment } from '../Shared/StartAddornment';
import { contains } from '../../util';

export class UpdateFacebookLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      facebookLink: ''
    }
  }

  onChange = (event) => {
    this.setState({
      facebookLink: event.target.value
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.updateFacebookLink(`${FACEBOOKURL}${this.state.facebookLink}`);
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <StartAddornment addornment={<FacebookIcon />}>
          <TextField
            defaultValue={removePrefix(this.props.userFacebookLink)}
            onChange={this.onChange}
            label="Facebook profile URL"
            InputProps={{
              startAdornment: <InputAdornment position="start">{FACEBOOKURL}</InputAdornment>,
              endAdornment: <IconButton
                variant="contained"
                size="small"
                onClick={this.onSubmit}
              >
                <ArrowRightRoundedIcon />
              </IconButton>
            }}
          />
        </StartAddornment>
      </form>
    );
  }
}

const FACEBOOKURL = "https://www.facebook.com/";

const removePrefix = (value) => {
  if (!value) {
    return '';
  }

  const hasPrefix = contains(value, FACEBOOKURL);
  return hasPrefix ? value.substr(FACEBOOKURL.length) : value.toString();
}
