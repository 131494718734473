import { FacebookPhoto } from './FacebookPhoto';
import { Link } from 'react-router-dom';

export const UserAvatar = ({ user }) => (
  <span className="user-profile-avatar">
    <FacebookPhoto user={user} width={32} />
    {user.name}
  </span>
);

export const LinkedUserAvatar = ({ user }) => (
  <Link to={`/player/${user.id}`}>
    <UserAvatar user={user} />
  </Link>
);