import React from 'react';

import { Page } from '../Shared/Page';
import { FacebookPhoto } from '../Shared/FacebookPhoto';
import { FacebookProfileLink } from './FacebookProfileLink';
import { LinkedTeamAvatar } from '../Shared/TeamAvatar';
import { Grid } from '@material-ui/core';

const Item = ({ collection, singularName, pluralName }) => {
  if (collection.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <dt>{collection.length === 1 ? singularName : pluralName}</dt>
      <dd>{collection.join(', ')}</dd>
    </React.Fragment>
  );
};

const Nicknames = ({ nicknames }) => <Item singularName="Nickname" pluralName="Nicknames" collection={nicknames} />;

const Teams = ({ teams }) => {
  if (teams.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <dt>{teams.length === 1 ? "Clan" : "Clans"}</dt>
      <dd>
        {teams.map((team, i) => [i > 0 && ", ", <LinkedTeamAvatar key={team.id} team={team} />])}
      </dd>
    </React.Fragment>
  )
};

const Unreals = ({ unreals }) => <Item singularName="Game" pluralName="Games" collection={unreals.map(unreal => unreal.shortName)} />;

const User = ({ user }) => (
  <React.Fragment>
    <dt>User name</dt>
    <dd>
      <FacebookProfileLink user={user}>
        {user.name}
      </FacebookProfileLink>
    </dd>
  </React.Fragment>
)

export class UserCard extends React.Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    const {
      user,
      userTeams,
      userUnreals,
    } = this.props;

    if (user) {
      return (
        <Page>
          <Grid container direction="row">
            <Grid item>
              <FacebookPhoto user={user} width={100} />
            </Grid>
            <Grid item>
              <dl>
                <User user={user} />
                <Nicknames nicknames={user.nicknames} />
                <Unreals unreals={userUnreals} />
                <Teams teams={userTeams} />
              </dl>
            </Grid>
          </Grid>
        </Page>
      );
    }

    return null;
  }
}
