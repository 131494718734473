import { reference } from '../database';

// LINK - UPDATE

export const SEND_FACEBOOK_LINK = 'SEND_FACEBOOK_LINK';
const sendFacebookLink = (userId, link) => ({
  type: SEND_FACEBOOK_LINK,
  userId,
  link,
});

export const DELIVER_FACEBOOK_LINK = 'DELIVER_FACEBOOK_LINK';
const deliverFacebookLink = (userId, link) => ({
  type: DELIVER_FACEBOOK_LINK,
  userId,
  link,
});

export const updateFacebookLink = (userId, link) => (dispatch) => {
  dispatch(sendFacebookLink(userId, link));

  return reference(`users/${userId}/facebookProfile`).set(link).then(() => {
    dispatch(deliverFacebookLink(userId, link));
  });
};
